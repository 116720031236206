import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://ea0e4b015ea74a65a31d7a065f68b2ca@o184978.ingest.sentry.io/4504875552014336',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'womenintechseo.com',
        'wts-server-o3ij5jno2q-nw.a.run.app',
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});
